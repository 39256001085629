import React from 'react';
import classes from './Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Button = ( {title, type='primary', close} ) => {
    const buttonClass =[classes.Btn];
    buttonClass.push(type);

    return (
        <button
            className={buttonClass.join(' ')}
            onClick={close}
        >
            <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;
            {title}
        </button>
    )
};

export default Button;