import React from 'react';

import classes from './PopupItem.module.scss'

const PopupItem = ( {content} ) => {

    const listItems = content.items.map((item, index) =>
            <li key={index}>{item}</li>
        );

    return (
        <div className={classes.startPopupItem}>
            <div className={classes.content}>
                <h3>{content.title}</h3>
                <ul>
                    {listItems}
                </ul>
            </div>
        </div>
    )
};

export default PopupItem;