import React from 'react';
import PopupItem from "./PopupItem/PopupItem";
import Button from "../../UI/Button/Button";
import classes from './StartTooltipPopupContent.module.scss'
import { ReactComponent as Close } from '../../../assets/images/CloseBtn.svg';

const StartTooltipPopupContent = ({close}) => {
    const mainTitle = 'Before you begin\n' +
        'Before you begin on your journey to find out how close you are to your retirement goals…';

    const items = [
        {
            content: {
                title: 'Consider the following:',
                items: [
                    'At what age would you like to retire?',
                    'How much monthly income do you think you need in retirement?',
                    'What percentage of your income would you like guaranteed in retirement?'
                ]
            }
        },
        {
            content: {
                title: 'Gather your most recent statements for these accounts:',
                items: [
                    'Registered Retirement Savings Plan (RRSP)',
                    'Locked-in account',
                    'Non-Registered',
                    'Workplace Savings',
                    'Tax Free Savings Account (TFSA)'
                ]
            }
        },
        {
            content: {
                title: 'What is your expected monthly income from the following sources:',
                items: [
                    'Registered Retirement Savings Plan (RRSP)',
                    'Canada Pension Plan (CPP)',
                    'Old Age Security (OAS)',
                    'Quebec Pension Plan (QPP)',
                    'Employer Pension'
                ],
            }
        }
    ];

    return (
        <div className={classes.Modal}>
            <div className="closeBtn">
                <Close onClick={close}/>
            </div>
            <h2>{mainTitle}</h2>
            {
                items && items.length>0 &&
                items.map((item, index) => {
                    return(
                        <PopupItem
                            key={index}
                            icon={item.icon}
                            content={item.content}
                        />

                    )
                })
            }
            <div className={classes.LinksListWrap}>
                <h3>If you don’t know your expected monthly income from these sources, go to:</h3>
                <ul className={classes.LinksList}>
                    <li>
                        <strong>CPP: </strong>
                        <a href = "https://www.canada.ca/en/services/benefits/publicpensions/cpp/cpp-benefit/amount.html">https://www.canada.ca/en/services/benefits/publicpensions/cpp/cpp-benefit/amount.html</a>
                    </li>
                    <li>
                        <strong>OAS: </strong>
                        <a href = "https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/payments.html">https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/payments.html</a>
                    </li>
                    <li>
                        <strong>QPP: </strong>
                        <a href = "https://www.rrq.gouv.qc.ca/en/retraite/rrq/Pages/">https://www.rrq.gouv.qc.ca/en/retraite/rrq/Pages/</a>
                    </li>
                </ul>
            </div>

            <div className="buttonWrap">
                <Button
                    title='Ok, got it'
                    close={close}
                />
            </div>
        </div>
    )

};

export default StartTooltipPopupContent;