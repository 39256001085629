import React from 'react';
import classes from './Tooltip.module.scss'
import StartTooltipPopupContent from "../../components/Tooltip/StartTooltipPopupContent/StartTooltipPopupContent";
import Popup from "reactjs-popup";
import Layer_18 from '../../assets/images/Layer_18.png'

const Tooltip = ( {type} ) => {
    return (
        <div className={classes.tooltipBox}>
            <div className={classes.title}>Tap for more information</div>
            <div className={classes.description}>

                <Popup modal trigger={<div className={classes.openPopup}>
                    <img
                        src = {Layer_18}
                        alt = "More info"
                        height={69}
                        width={69}
                    />
                </div>}>
                {close => <StartTooltipPopupContent close={close}/>}
                </Popup>

            <span>More Info</span>
            </div>
        </div>
    )
};

export default Tooltip;