import React, { useEffect } from 'react';
import classes from './Home.module.scss'
import Tooltip from "../../components/Tooltip/Tooltip";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SimpleButton from "../../components/UI/SimpleButton/SimpleButton";
import { resetFirstStep } from "../../store/actions/aboutYou";
import {retirementCalcLinks} from "../../Routes/RoutesConst";
import { thirdStepCompleted } from "../../store/actions/savingsAndInvestments";
import { fourthStepCompleted } from "../../store/actions/SourceOfIncomeActions";
import { secondStepCompleted } from "../../store/actions/investorProfileActions";

const Home = (props) => {

    const content = {
        title: 'Retirement',
    };

    useEffect(()=>{
        props.secondStepCompleted(false);
        props.thirdStepCompleted(false);
        props.fourthStepCompleted(false);
        //props.resetFirstStep('aboutYou')
    });

    return (

        <div className='StepStart'>

            <Helmet>
                <title>How close are you to your retirement goals?</title>
            </Helmet>

            <header className="App-header">
                <h1>{content.title}</h1>
              </header>
            <p>To get a better understanding of how close you are to your goals
we will ask you questions in the following sections:</p>

            <div className={classes.SectionsList}>
                <ul>
                    <li>About You</li>
                    <li>Investor Profile</li>
                    <li>Savings & <br/> Investments</li>
                    <li>INCOME SOURCES  <br/> SUMMARY</li>
                </ul>
                <p>You’ll get a result tailored to your situation.</p>

                <NavLink to={retirementCalcLinks.aboutYou}>
                    <SimpleButton title={'Continue'}/>
                </NavLink>
            </div>

            <Tooltip type={'home'}/>
        </div>

    )
};

function mapDispatchToProps(dispatch) {
    return {
        resetFirstStep: ()=>dispatch(resetFirstStep()),
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val)),
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val)),
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(null, mapDispatchToProps)(Home);